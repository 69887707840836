<template>
  <div class="explorer">
    <div class="explorer__tools"></div>
    <div class="explorer__wrapper">
      <h1>Recent Files</h1>
      <div class="explorer__wrapper-files">
        <File v-for="(file, key) in $store.state.file.files" :key="key" :file="file" @click="openFile(file)"/>
      </div>
    </div>
  </div>
</template>
<script>
  import File from "@/components/Files/BaseFile";

  export default {
    components: {
      File
    },
    data() {
      return {
      }
    },
    computed: {

    },
    methods: {
      openFile(file) {
        this.$router.push({ path: `/d/${file}`});
      }
    }
  }
</script>
<style lang="scss" scoped>

</style>